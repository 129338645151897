.postWrapper {
	padding-bottom: 25px;
	clear:both;
}
.postTitle {
	border-bottom-width: 1px;
	border-bottom-style: solid;
	border-bottom-color: #DDDDDD;
	margin-bottom: 15px;
}
.postTitle h2 {
	font-size: 20px;
	font-weight: normal;
	margin: 0px;
	padding: 0px;
}
.postTitle h3 {
	font-size: 10px;
	margin: 0px;
	padding-top: 2px;
	padding-right: 0px;
	padding-bottom: 2px;
	padding-left: 0px;
}
.postContent {
	padding-bottom: 10px;
}
.postBookmarks {
	padding-bottom: 10px;
}
.postBookmarks h4 {
	font-size: 12px;
	font-weight: bold;
}
.commentWrapper {
	padding-bottom: 15px;
}
.commentContent {
	padding-bottom: 0px;
}
.commentDetails  {
	margin-bottom:5px;
	padding: 0px;
}
.commentDetails  h4.username{
	display:inline;
}

.postError {
	font-size: 16px;
}

.tags{
	font-weight:bold;
	padding-bottom: 10px;
}
.tags UL{
	display:inline;
}
.tags UL LI{
font-weight:normal;
	display:inline;
	padding-right:6px;
}


.tags h4 {
font-size:12px;
font-weight:bold;
}

.menu-tags, .menu-categories, .menu-recent{
	margin:5px 9px;
}
.menu-tags h5, .menu-categories h5, .menu-recent h5{font-size:11px;}

.block-blog .menu-tags UL LI{
	display:inline;
	padding:2px;
	margin-left:10px;
}
.block-blog .menu-categories UL LI{
	margin-left:10px;
}
.block-blog .menu-recent UL LI{
	margin-left:10px;
}
.block-blog .menu-recent UL LI a,.block-blog .menu-categories UL LI a,.block-blog .menu-tags UL LI a{font-size:11px;}

.tag-count-1{
	font-size:1em!important;
}
.tag-count-2{
	font-size:1.2em!important;
}
.tag-count-3{
	font-size:1.4em!important;
}
.tag-count-4{
	font-size:1.6em!important;
}
.tag-count-5{
	font-size:1.8em!important;
}
.tag-count-6{
	font-size:2em!important;
}
.tag-count-7{
	font-size:2em!important;
}
.tag-count-8{
	font-size:2em!important;
}
.tag-count-9{
	font-size:2em!important;
}
.tag-count-10{
	font-size:2em!important;
}

.block-blog .head h4{
padding-left: 20px;
}

.block-blog .block-title strong {
background-image:url(../images/icon_blog.png);
background-repeat:no-repeat;
padding-left:21px;
text-align:left;
}

.postWrapper .poster{
	display:inline;
}
.input-box.aw-blog-comment-area{width:100%;}

.widget-latest li{font-size:12px;padding:5px 10px;}
.block-blog .menu-categories{margin:0;padding:5px 9px;}

A.awblog_current_comment { text-decoration:none!important; font-weight: bold!important; }

body.blog-post-view #page, body.blog-index-list #page{
	color:#444444;
	font-family: var(--bn--primary-font);
	font-size:13px;
}

body.blog-post-view div.main,
body.blog-post-view div.container div.col-xs-12.col-sm-12,
body.blog-index-list div.main,
body.blog-index-list div.container div.col-xs-12.col-sm-12 {
	box-shadow: 0 2px 6px rgba(100, 100, 100, 0.3);
    margin-bottom: 3.42857rem;
    margin-top: 3.42857rem;
    padding: 2.85714rem 2.85714rem 0;
	background-color:#fff;
	width:960px;
}

body.blog-index-list div.main.col-xs-12,
body.blog-post-view div.main.col-xs-12 {
	width:75%;
	box-shadow:none;
	margin-top: 0;
    padding: 15px 0 0;
}

body.blog-post-view div.main .col-main, body.blog-index-list div.main .col-main{
	width:625px;
	margin-top: 0;
    padding-top: 0;
}

body.blog-post-view div.main .col-right, body.blog-index-list div.main .col-right{
	width:250px;
}

body.blog-post-view div.main .col-right .widget-area, body.blog-index-list div.main .col-right .widget-area {
    margin: 0 0 0;
}

body.blog-post-view h2, body.blog-index-list h2{
    font-size: 1.42857rem;
    font-weight: normal;
    line-height: 1.2;
}

body.blog-post-view h2 a, body.blog-index-list h2 a{
    color: #21759B;
    outline: medium none;
}

body.blog-post-view h2 a:hover, body.blog-index-list h2 a:hover{
    color: #0F3647;
}

body.blog-post-view .col-main article, body.blog-index-list .col-main article {
    -moz-hyphens: auto;
    border-bottom: 4px double #EDEDED;
    margin-bottom: 5.14286rem;
    padding-bottom: 1.71429rem;
    word-wrap: break-word;
    font-size: 13px;
}

body.blog-post-view .entry-header, body.blog-index-list .entry-header {
    margin-bottom: 1.71429rem;
    box-shadow: none;
    z-index: 1;
}

body.blog-post-view header.entry-header.fixed, body.blog-index-list header.entry-header.fixed {
    background-color: #fff;
}

body.blog-post-view .entry-header .entry-title, body.blog-index-list .entry-header .entry-title {
    font-size: 1.57143rem;
	font-weight: normal;
    line-height: 1.2;
    color: #21759B;
}

body.blog-post-view .entry-header .entry-title a, body.blog-index-list .entry-header .entry-title a{
    color: #21759B;
    outline: medium none;
}

body.blog-post-view .entry-header .entry-title a:hover, body.blog-index-list .entry-header .entry-title a:hover{
    color: #0F3647;
}

body.blog-post-view .entry-content, body.blog-index-list .entry-content{
    line-height: 1.71429;
}

body.blog-post-view .entry-header img.thumbnail, body.blog-index-list .entry-header img.thumbnail{
    margin-right:5px;
    margin-bottom: 1.71429rem;
	border-radius: 3px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}

body.blog-post-view .col-main footer.entry-meta, body.blog-index-list .col-main footer.entry-meta{
    color: #757575;
    font-size: 0.80rem;
    line-height: 1.84615;
    margin-top: 1.71429rem;
    clear: both;
}

body.blog-post-view .col-main footer a:hover, body.blog-index-list .col-main footer a:hover {
    color: #757575;
    text-decoration: none;
}

body.blog-post-view .col-right,  body.blog-index-list .col-right{
    padding: 0;
}

body.blog-post-view .col-right .widget-area,  body.blog-index-list .col-right .widget-area {
    margin: 1.71429rem 0 0;
}


body.blog-post-view .col-right .widget-area .widget, body.blog-index-list .col-right .widget-area .widget {
    -moz-hyphens: auto;
    margin-bottom: 3.42857rem;
    word-wrap: break-word;
}
body.blog-post-view .col-right .widget-area .widget h3, body.blog-index-list .col-right .widget-area .widget h3 {
    margin-bottom: 1.71429rem;
	color: #636363;
    font-size: 0.785714rem;
    font-weight: bold;
    line-height: 2.18182;
    text-transform: uppercase;
	padding:0;
}


body.blog-post-view .col-right .widget-area .widget a, body.blog-index-list .col-right .widget-area .widget a {
    color: #757575;
	text-decoration:underline;
	font-size: 13px;
}

body.blog-post-view .col-right .widget-area .widget a:hover, body.blog-index-list .col-right .widget-area .widget a:hover {
    color: #21759B;
}

body.blog-post-view .entry-content p, body.blog-index-list .entry-content p{
	line-height: 1.71429;
    margin: 0 0 1.71429rem;
}

body.blog-post-view .entry-content ul li, body.blog-index-list .entry-content ul li{
   list-style-type: disc;
   list-style-position: inside;
}
body.blog-post-view .entry-content ol li, body.blog-index-list .entry-content ol li{
   list-style-type: decimal;
   list-style-position: inside;
}
body.blog-post-view .entry-content ul ul li, body.blog-post-view .entry-content ol ul li,
body.blog-index-list .entry-content ul ul li, body.blog-index-list .entry-content ol ul li{
   list-style-type: circle;
   list-style-position: inside;
   margin-left: 15px;
}
body.blog-post-view .entry-content ol ol li, body.blog-post-view .entry-content ul ol li,
body.blog-index-list .entry-content ol ol li, body.blog-index-list .entry-content ul ol li{
   list-style-type: lower-latin;
   list-style-position: inside;
   margin-left: 15px;
}
body.blog-post-view .entry-content ul ul, body.blog-post-view .entry-content ol ul,
body.blog-index-list .entry-content ul ul, body.blog-index-list .entry-content ol ul,
body.blog-post-view .entry-content ol ol, body.blog-post-view .entry-content ul ol,
body.blog-index-list .entry-content ol ol, body.blog-index-list .entry-content ul ol{
   margin-left: 15px;
}
